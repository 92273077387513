import React, {Component, useEffect, useState} from 'react';
import axios from "axios";
import {Routes, Route, useParams, Navigate} from 'react-router-dom';
import "yet-another-react-lightbox/styles.css";
import Header from "./Components/Header";
import DynamicFormContact from './Components/DynamicFormContact';
import {toast, ToastContainer} from "react-toastify";

const ManageAccount = () => {

    const [firstname, setFirstname] = useState('');
    const [lastname, setLastname] = useState('');
    const [phone, setPhone] = useState('');
    const [company, setCompany] = useState('');
    const [paymentTerms, setPaymentTerms] = useState('');
    const [creditLine, setCreditLine] = useState('');
    const [fobOrigin, setFobOrigin] = useState('');
    const [fobDest, setFobDest] = useState('');
    const [shippingAddress, setShippingAddress] = useState('');
    const [redirect, setRedirect] = React.useState(false);
    

    useEffect(() => {
        axios.get(`suppliers/get-manage-account`)
            .then(response => {
                const supplier = response.data;
                console.log(supplier);
                setFirstname(supplier.firstname);
                setLastname(supplier.lastname);
                setPhone(supplier.phone);
                setCompany(supplier.company);
                setPaymentTerms(supplier.paymentTerms);
                setCreditLine(supplier.creditLine);
                setFobOrigin(supplier.fobOrigin);
                setFobDest(supplier.fobDest);
                setShippingAddress(supplier.shippingAddress);
            })
    }, []);

    const handleSubmit = (event) => {
        event.preventDefault();
        let accountObj = {
            firstname:  firstname,
            lastname: lastname,
            phone: phone,
            company: company,
            paymentTerms: paymentTerms,
            creditLine: creditLine,
            fobOrigin: fobOrigin,
            fobDest: fobDest,
            shippingAddress: shippingAddress
        }
        axios.post(`suppliers/update-account`, accountObj)
            .then(response => {
                toast.success('Account Updated Successfully!');
            }).catch(error => {
            console.error('There was an error!', error);

        });
    };

    const handleChange = (name, value) => {
        if(name === 'firstname') {
            setFirstname(value)
        }
        if(name === 'lastname') {
            setLastname(value)
        }
        if(name === 'phone') {
            setPhone(value)
        }
        if(name === 'company') {
            setCompany(value)
        }
        if(name === 'paymentTerms') {
            setPaymentTerms(value)
        }
        if(name === 'creditLine') {
            setCreditLine(value)
        }
        if(name === 'fobOrigin') {
            setFobOrigin(value)
        }
        if(name === 'fobDest') {
            setFobDest(value)
        }
        if(name === 'shippingAddress') {
            setShippingAddress(value)
        }
    };


    if(redirect)
    {
        return (<Navigate to="/home"/>)
    }
    else {
        return (
            <>
                <Header/>

                <div className="banner text-center">
                    <div className="container">
                        <div className="row" id="banner">
                            <div className="col-lg-9 mx-auto">

                            </div>
                        </div>
                    </div>

                </div>


                <section className="section pb-0" style={{marginBottom: '130px'}}>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 mb-12">

                                <article className="card">
                                    <div className="card-body">
                                        <div className="row">

                                            <div className="col-lg-12 mb-12" id="addRecord">
                                                <div className="container">
                                                    <h3 style={{marginBottom:'20px'}}>
                                                        Manage Account
                                                    </h3>
                                                    <p className="line"></p>
                                                </div>

                                                <div className="container">
                                                    <form onSubmit={handleSubmit}>

                                                        <div className="mb-3">
                                                            <label className="form-label">First Name</label>
                                                            <div className="input-group">
                                                                <input
                                                                    type='text'
                                                                    className="form-control"
                                                                    id='firstname'
                                                                    name='firstname'
                                                                    value={firstname}
                                                                    onChange={(e) => handleChange('firstname', e.target.value)}
                                                                    required='true'
                                                                />
                                                            </div>
                                                        </div>

                                                        <div className="mb-3">
                                                            <label className="form-label">Last Name</label>
                                                            <div className="input-group">
                                                                <input
                                                                    type='text'
                                                                    className="form-control"
                                                                    id='lastname'
                                                                    name='lastname'
                                                                    value={lastname}
                                                                    onChange={(e) => handleChange('lastname', e.target.value)}
                                                                    required='true'
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="mb-3">
                                                            <label className="form-label">Phone</label>
                                                            <div className="input-group">
                                                                <input
                                                                    type='text'
                                                                    className="form-control"
                                                                    id='phone'
                                                                    name='phone'
                                                                    value={phone}
                                                                    onChange={(e) => handleChange('phone', e.target.value)}
                                                                    required='true'
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="mb-3">
                                                            <label className="form-label">Company</label>
                                                            <div className="input-group">
                                                                <input
                                                                    type='text'
                                                                    className="form-control"
                                                                    id='company'
                                                                    name='company'
                                                                    value={company}
                                                                    onChange={(e) => handleChange('company', e.target.value)}
                                                                    required='true'
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="mb-3">
                                                            <label className="form-label">Payment Terms</label>
                                                            <div className="input-group">
                                                                <input
                                                                    type='text'
                                                                    className="form-control"
                                                                    id='paymentTerms'
                                                                    name='paymentTerms'
                                                                    value={paymentTerms}
                                                                    onChange={(e) => handleChange('paymentTerms', e.target.value)}
                                                                    required='true'
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="mb-3">
                                                            <label className="form-label">Credit Line</label>
                                                            <div className="input-group">
                                                                <input
                                                                    type='text'
                                                                    className="form-control"
                                                                    id='creditLine'
                                                                    name='creditLine'
                                                                    value={creditLine}
                                                                    onChange={(e) => handleChange('creditLine', e.target.value)}
                                                                    required='true'
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="mb-3">
                                                            <label className="form-label">Fob Origin</label>
                                                            <div className="input-group">
                                                                <input
                                                                    type='text'
                                                                    className="form-control"
                                                                    id='fobOrigin'
                                                                    name='fobOrigin'
                                                                    value={fobOrigin}
                                                                    onChange={(e) => handleChange('fobOrigin', e.target.value)}
                                                                    required='true'
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="mb-3">
                                                            <label className="form-label">Fob Destination</label>
                                                            <div className="input-group">
                                                                <input
                                                                    type='text'
                                                                    className="form-control"
                                                                    id='fobDest'
                                                                    name='fobDest'
                                                                    value={fobDest}
                                                                    onChange={(e) => handleChange('fobDest', e.target.value)}
                                                                    required='true'
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="mb-3">
                                                            <label className="form-label">Shipping Address</label>
                                                            <div className="input-group">
                                                                <input
                                                                    type='text'
                                                                    className="form-control"
                                                                    id='shippingAddress'
                                                                    name='shippingAddress'
                                                                    value={shippingAddress}
                                                                    onChange={(e) => handleChange('shippingAddress', e.target.value)}
                                                                    required='true'
                                                                />
                                                            </div>
                                                        </div>
                                                        
                                                        

                  
                                                        

                                                        <button type="submit" className="btn btn-primary">Update</button>
                                                    </form>
                                                    <ToastContainer />
                                                </div>
                                            </div>
                                        </div>


                                    </div>
                                </article>

                            </div>


                            <div className="col-12">
                                <div className="border-bottom border-default"></div>
                            </div>
                        </div>
                    </div>
                    <ToastContainer />
                </section>
            </>
        );
    }

}

export default ManageAccount;
