import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import axios from "axios";
import {toast, ToastContainer} from "react-toastify";

const DynamicFormComponent = ({ fields }) => {
    const initialState = Object.fromEntries(fields.map(field => [field.name, field.defaultValue || '']));
    const [formValues, setFormValues] = useState(initialState);
    const today = new Date();

    const handleChange = (name, value) => {
        // Format the dollar amount
        if (name === 'Tier1Cost' || name === 'Tier2Cost' || name === 'Tier3Cost') {
            // Remove non-numeric characters
            const numericValue = value.replace(/[^0-9.]/g, '');
            // Format as currency
            const formattedValue = Number(numericValue).toLocaleString('en-US', {
                style: 'currency',
                currency: 'USD',
            });
            setFormValues({ ...formValues, [name]: formattedValue });
        }
        else {
            setFormValues({ ...formValues, [name]: value });
        }
    };

    const openPage = () => {
        window.location.href = "/home";
    }

    const handleDateChange = (name, date) => {
        handleChange(name, date);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        // You can perform further actions with the form data here
        console.log('Form submitted with values:', formValues);

        axios.post(`suppliers/add-supplier`, formValues)
            .then(response => {
                toast.success('Record Added Successfully!');
                setTimeout(() => {
                    openPage();
                }, 2000);

            }).catch(error => {
            console.error('There was an error!', error);
           
        });
    };

    return (
        <div className="container">
            <form onSubmit={handleSubmit}>
                {fields.map(field => (
                    <div key={field.name} className="mb-3" style={{display: (field.hidden) ? 'none': 'block'}}>
                        <label htmlFor={field.name} className="form-label">{field.label}</label>
                        {field.type === 'date' ? (
                            <DatePicker
                                className="form-control"
                                id={field.name}
                                name={field.name}
                                minDate={today}
                                selected={formValues[field.name]}
                                onChange={(date) => handleDateChange(field.name, date)}
                                dateFormat="MM-dd-yyyy"
                                required={field.required || false}
                            />
                        ) : (
                            <div className="input-group">
                                {field.icon && (
                                    <span className="input-group-text">
                                      <i style={{fontSize:'18px'}} className={field.icon}></i>
                                    </span>
                                )}
                                <input
                                    type={field.type === 'tel' ? 'tel' : 'text'} // Use 'tel' type for phone numbers
                                    className="form-control"
                                    id={field.name}
                                    name={field.name}
                                    value={formValues[field.name]}
                                    onChange={(e) => handleChange(field.name, e.target.value)}
                                    required={field.required || false}
                                />
                            </div>
                        )}
                    </div>
                ))}
                <button type="submit" className="btn btn-primary">Submit</button>
            </form>
            <ToastContainer />
        </div>
    );
};

export default DynamicFormComponent;
