import React, {Component, useEffect, useState} from 'react';
import axios from "axios";
import {Routes, Route, useParams, Navigate} from 'react-router-dom';
import "yet-another-react-lightbox/styles.css";
import Header from "./Components/Header";
import DynamicFormContact from './Components/DynamicFormContact';
import {toast, ToastContainer} from "react-toastify";

const EditContact = () => {

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [cellPhone, setCellPhone] = useState('');
    const [officePhone, setOfficePhone] = useState('');
    const [redirect, setRedirect] = React.useState(false);

    let { contactId } = useParams();

    useEffect(() => {
        axios.get(`suppliers/get-contact-data/${contactId}`)
            .then(response => {
                const contact = response.data.contact;
                console.log(contact);
                setName(contact.Name);
                setEmail(contact.Email);
                setCellPhone(contact.CellPhone);
                setOfficePhone(contact.OfficePhone);
            })
    }, []);

    const handleSubmit = (event) => {
        event.preventDefault();
        let contactObj = {
            Name:  name,
            Email: email,
            CellPhone: cellPhone,
            OfficePhone: officePhone,
            ContactId: contactId
        }
        axios.post(`suppliers/update-contact`, contactObj)
            .then(response => {
                toast.success('Contact Updated Successfully!');
                setTimeout(() => {
                    window.location.href = "/manage-contact";
                }, 2000);


            }).catch(error => {
            console.error('There was an error!', error);

        });
    };

    const handleChange = (name, value) => {
        if(name === 'name') {
            setName(value)
        }
        if(name === 'email') {
            setEmail(value)
        }
        if(name === 'cellPhone') {
            setCellPhone(value)
        }
        if(name === 'officePhone') {
            setOfficePhone(value)
        }
    };


    if(redirect)
    {
        return (<Navigate to="/home"/>)
    }
    else {
        return (
            <>
                <Header/>

                <div className="banner text-center">
                    <div className="container">
                        <div className="row" id="banner">
                            <div className="col-lg-9 mx-auto">

                            </div>
                        </div>
                    </div>

                </div>


                <section className="section pb-0" style={{marginBottom: '130px'}}>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 mb-12">

                                <article className="card">
                                    <div className="card-body">
                                        <div className="row">

                                            <div className="col-lg-12 mb-12" id="addRecord">
                                                <div className="container">
                                                    <h3 style={{marginBottom:'20px'}}>
                                                        Edit Contact
                                                    </h3>
                                                    <p className="line"></p>
                                                </div>

                                                <div className="container">
                                                    <form onSubmit={handleSubmit}>
                                                        
                                                            <div className="mb-3">
                                                                <label className="form-label">Name</label>
                                                                <div className="input-group">
                                                                    <input
                                                                        type='text'
                                                                        className="form-control"
                                                                        id='name'
                                                                        name='name'
                                                                        value={name}
                                                                        onChange={(e) => handleChange('name', e.target.value)}
                                                                        required='true'
                                                                    />
                                                                </div>
                                                            </div>

                                                        <div className="mb-3">
                                                            <label className="form-label">Email</label>
                                                            <div className="input-group">
                                                                <input
                                                                    type='email'
                                                                    className="form-control"
                                                                    id='email'
                                                                    name='email'
                                                                    value={email}
                                                                    onChange={(e) => handleChange('email', e.target.value)}
                                                                    required='true'
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="mb-3">
                                                            <label className="form-label">Cell Phone</label>
                                                            <div className="input-group">
                                                                <input
                                                                    type='text'
                                                                    className="form-control"
                                                                    id='cellPhone'
                                                                    name='cellPhone'
                                                                    value={cellPhone}
                                                                    onChange={(e) => handleChange('cellPhone', e.target.value)}
                                                                    required='true'
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="mb-3">
                                                            <label className="form-label">Office Phone</label>
                                                            <div className="input-group">
                                                                <input
                                                                    type='text'
                                                                    className="form-control"
                                                                    id='officePhone'
                                                                    name='officePhone'
                                                                    value={officePhone}
                                                                    onChange={(e) => handleChange('officePhone', e.target.value)}
                                                                />
                                                            </div>
                                                        </div>
                                                       
                                                        <button type="submit" className="btn btn-primary">Submit</button>
                                                    </form>
                                                    <ToastContainer />
                                                </div>
                                            </div>
                                        </div>


                                    </div>
                                </article>

                            </div>


                            <div className="col-12">
                                <div className="border-bottom border-default"></div>
                            </div>
                        </div>
                    </div>
                    <ToastContainer />
                </section>
            </>
        );
    }

}

export default EditContact;
