import React, { Component, useState }  from 'react';
import axios from "axios";
import $ from "jquery";
import Header from "./Components/Header";
import CsvBoxxComponent from "./Components/CsvBoxxComponent";
import 'react-datepicker/dist/react-datepicker.css'
import {Navigate} from "react-router-dom";
import {th} from "date-fns/locale";
import {toast, ToastContainer} from "react-toastify";

export class Upload extends Component{
    state = {
        selectedFile: null,
        redirect: false
    }

    openPage = () => {
        window.location.href = "/add-record";
    }

    // On file select (from the pop up)
    onFileChange = event => {

        // Update the state
        this.setState({ selectedFile: event.target.files[0] });

    };
    
    onDownload = () => {
        const element = document.createElement("a");
        element.href = axios.defaults.baseURL + 'suppliers/download-sample-file';
        element.download = "Supplier-Import-File-Format Template.xlsx";
        document.body.appendChild(element); // Required for this to work in FireFox
        element.click();
    }

    // On file upload (click the upload button)
    onFileUpload = () => {

        // Create an object of formData
        const formData = new FormData();

        // Update the formData object
        formData.append(
            "myFile",
            this.state.selectedFile,
            this.state.selectedFile.name
        );

        // Details of the uploaded file
        console.log(this.state.selectedFile);

        // Request made to the backend api
        // Send formData object
        axios.post(`/suppliers/file-upload`, formData).then(response => {
            //window.location.reload();
            console.log(response.data.resp)
            if(response.data.resp === "OK")
            {
                toast.success('Import Completed Successfully!');
                setTimeout(() => {
                    this.setState({ redirect: true })
                }, 2000);
            }
            else
            {
                toast.error('Import Failed : Incorrect Data Format!');
            }
        }).catch(error => {
            console.error('There was an error!', error);
        });

    };

    // File content to be displayed after
    // file upload is complete

    componentDidMount() {
        axios.get(`suppliers/is-logged-in`)
            .then(response => {
                const authorized = response.data.authorized;
                if(authorized === false) {
                    this.setState({ redirect: true })
                }
                
            })
    }
    
    render() {
        if(this.state.redirect)
        {
            return (<Navigate to="/home"/>)
        }
        else {
            return (
                <>
                    <Header  />




                    <section className="section pb-0" style={{ marginBottom: '130px'}}>
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12 mb-12 row" >


                                </div>


                                <div className="col-lg-12 mb-12">



                                    <article className="card">


                                        <div className="card-body">
                                            <div className="row col-lg-12 mb-12">
                                                <div className="col-lg-6 mb-12" style={{border:'1px #05aced dashed'}}>
                                                    <h3 style={{marginTop:'10px'}}>
                                                       Import Data
                                                    </h3>
                                                    <h6>Choose an xls before pressing the Upload button</h6>
                                                    <p onClick={this.onDownload} style={{fontSize:"13px", color:"green", cursor:"pointer"}}><i className="fa-solid fa-download" style={{fontSize:"18px"}}></i> Download Sample Import File</p>
                                                    <br/>
                                                    {/*<div style={{marginBottom:'10px'}}>*/}
                                                    {/*    <input type="file" onChange={this.onFileChange} />*/}
                                                    {/*    <br/>*/}
                                                    {/*    <button className='btn btn-primary' onClick={this.onFileUpload} style={{marginTop:'30px'}}>*/}
                                                    {/*        <i className="fa-solid fa-upload"></i> Upload!*/}
                                                    {/*    </button>*/}
                                                    {/*</div>*/}
                                                    <div id={'CsvBoxComp'} style={{marginBottom: '20px'}}>
                                                        <CsvBoxxComponent />
                                                    </div>
               
                                                </div>
                                                <div className="col-lg-6 mb-12 text-center" style={{border:'1px #05aced dashed', borderLeft:'0px'}} >
                                                    <button className='btn btn-primary' onClick={this.openPage} style={{marginTop:'95px'}}>
                                                        <i className="fa-solid fa-plus"></i> Add Manually
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </article>
                                </div>



                                <div className="col-12">
                                    <div className="border-bottom border-default"></div>
                                </div>
                            </div>
                        </div>
                        <ToastContainer />
                    </section>
                </>
            );
        }
        
    }
}

export default Upload;
