import Logo from './logo.png';
import React, { Component } from "react";
import axios from "axios";
import { Navigate } from 'react-router-dom';
import {tr} from "date-fns/locale";

export class ForgotPassword extends Component{

    constructor(props) {
        super(props);
    };

    state = {
        username: '',
        code: '',
        newPassword: '',
        reTypePassword: '',
        loginStatus: 0,
        redirect: false,
        page: 0
    }

    handleUsernameInput = event => {
        this.setState({ username: event.target.value });
    };
    handleCodeInput = event => {
        this.setState({ code: event.target.value });
    };
    handleNewPasswordInput = event => {
        this.setState({ newPassword: event.target.value });
    };

    handleReTypePassword = event => {
        this.setState({ reTypePassword: event.target.value });
    };

    onKeyUp  = event => {
        if (event.charCode === 13) {
            this.resetPassword(event);
        }
    };

    onKeyUpCode  = event => {
        if (event.charCode === 13) {
            this.verifyCode(event);
        }
    };

    onKeyUpPassword  = event => {
        if (event.charCode === 13) {
            this.newPassword(event);
        }
    };

    onKeyUpReTypePassword = event => {
        if (event.charCode === 13) {
            this.newPassword(event);
        }
    };

    resetPassword = event => {
        this.setState({ loginStatus: 0 });
        let userObj = {
            username:  this.state.username
        }
        if(this.state.username !== "")
        {
            axios.post(`supauth/forgot-password`, userObj)
                .then(response => {
                    if(response.data.otpSent === 200) {
                        this.setState({ page: 1, username: response.data.email });
                    } else {
                        this.setState({ loginStatus: 4 });
                    }
                }).catch(error => {
                console.error('There was an error!', error);
                this.setState({ loginStatus: 2 });
            });
        }
    };

    verifyCode = event => {
        this.setState({ loginStatus: 0 });
        let verifyObj = {
            code:  this.state.code,
            username:  this.state.username
        }
        if(this.state.code !== "")
        {
            axios.post(`supauth/verify-code`, verifyObj)
                .then(response => {
                    if(response.data.codeVerified === 200) {
                        this.setState({ page: 2, username: response.data.email });
                    } else {
                        this.setState({ loginStatus: 3 });
                    }
                }).catch(error => {
                console.error('There was an error!', error);
                this.setState({ loginStatus: 2 });
            });
        }

    };

    newPassword = event => {
        this.setState({ loginStatus: 0 });
        let userObj = {
            password:  this.state.newPassword,
            username:  this.state.username
        }
        if(this.state.newPassword !== "" && this.state.newPassword === this.state.reTypePassword)
        {
            axios.post(`supauth/new-password`, userObj)
                .then(response => {
                    if(response.data.passwordUpdated === 200) {
                        this.setState({ loginStatus: 1 });

                        setTimeout(
                            () => this.setState({ redirect: true }),
                            2000
                        );
    
                    }
                }).catch(error => {
                console.error('There was an error!', error);
                this.setState({ loginStatus: 2 });
            });
        }
    };
    componentDidMount() {
        
    }

    render() {
        let authStatus;
        if(this.state.loginStatus === 0)
        {
            authStatus = <p></p>
        }
        else if(this.state.loginStatus === 1)
        {
            authStatus = <p style={{color:'green'}}>Password changed Successfully</p>
        }
        else if(this.state.loginStatus === 2)
        {
            authStatus = <p style={{color:'#f00'}}>Failed</p>
        }
        else if(this.state.loginStatus === 3)
        {
            authStatus = <p style={{color:'#f00'}}>Incorrect Code</p>
        }
        else if(this.state.loginStatus === 4)
        {
            authStatus = <p style={{color:'#f00'}}>Email does not exist</p>
        }
        
        if(this.state.page === 1)
        {
            return (<>
                <header className="navigation fixed-top">
                    <div className="container">
                        <nav className="navbar navbar-expand-lg navbar-white">
                            <a className="navbar-brand order-1" href="/">
                                <img className="img-fluid" width="100px" src={Logo} alt="Peak"/>
                            </a>
                            <div className="collapse navbar-collapse text-center order-lg-2 order-3" id="navigation">

                            </div>

                            <div className="order-2 order-lg-3 d-flex align-items-center">

                            </div>

                        </nav>
                    </div>
                </header>


                <section className="section-sm" style={{marginTop:'120px'}}>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 mx-auto" style={{border : "1px solid #EBEBEB", padding:"28px 24px"}}>

                                <div className="content mb-5">
                                    <h3 id="we-would-love-to-hear-from-you"><i className="fa-solid fa-user-shield"></i> Please enter the code</h3>
                                    <p style={{borderBottom:'1px #EBEBEB solid'}}></p>
                                </div>
                                <div className="form-group">
                                    <p style={{color:'green'}}>We've just sent you a one-time password to your email!</p>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="name">Code</label>
                                    <input type="text" name="code" onKeyPress={this.onKeyUpCode} onChange={this.handleCodeInput} id="code" className="form-control" />
                                </div>
                                <div className="form-group" style={{color:'#f00'}}>
                                    {authStatus}
                                </div>
                                <button type="button" onClick={this.verifyCode} className="btn btn-primary"> Verify Code</button>
                            </div>
                        </div>
                    </div>
                </section>
            </>)
        }
        else if(this.state.redirect === true && this.state.page === 2)
        {
            return (<Navigate to="/login"/>)
        }
        else if(this.state.page === 2)
        {
            return (<>
                <header className="navigation fixed-top">
                    <div className="container">
                        <nav className="navbar navbar-expand-lg navbar-white">
                            <a className="navbar-brand order-1" href="/">
                                <img className="img-fluid" width="100px" src={Logo} alt="Peak"/>
                            </a>
                            <div className="collapse navbar-collapse text-center order-lg-2 order-3" id="navigation">

                            </div>

                            <div className="order-2 order-lg-3 d-flex align-items-center">

                            </div>

                        </nav>
                    </div>
                </header>


                <section className="section-sm" style={{marginTop:'120px'}}>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 mx-auto" style={{border : "1px solid #EBEBEB", padding:"28px 24px"}}>

                                <div className="content mb-5">
                                    <h3 id="we-would-love-to-hear-from-you"><i className="fa-solid fa-user-shield"></i> Create New Password</h3>
                                    <p style={{borderBottom:'1px #EBEBEB solid'}}></p>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="newPassword">New Password</label>
                                    <input type="password" name="newPassword" onKeyPress={this.onKeyUpPassword} onChange={this.handleNewPasswordInput} id="newPassword" className="form-control" />
                                </div>
                                <div className="form-group">
                                    <input type="hidden" name="hiddenField" id="hiddenField" className="form-control" />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="reTypeOtherPassword">Re-Type Password</label>
                                    <input type="password" name="reTypeOtherPassword" onKeyPress={this.onKeyUpReTypePassword} onChange={this.handleReTypePassword} id="reTypeOtherPassword" className="form-control" />
                                </div>
                                <div className="form-group" style={{color:'green'}}>
                                    {authStatus}
                                </div>
                                <button type="button" onClick={this.newPassword} className="btn btn-primary"> Reset Password</button>
                            </div>
                        </div>
                    </div>
                </section>
            </>)
        }
        else
        {
            return (
                <>
                    <header className="navigation fixed-top">
                        <div className="container">
                            <nav className="navbar navbar-expand-lg navbar-white">
                                <a className="navbar-brand order-1" href="/">
                                    <img className="img-fluid" width="100px" src={Logo} alt="Peak"/>
                                </a>
                                <div className="collapse navbar-collapse text-center order-lg-2 order-3" id="navigation">

                                </div>

                                <div className="order-2 order-lg-3 d-flex align-items-center">

                                </div>

                            </nav>
                        </div>
                    </header>


                    <section className="section-sm" style={{marginTop:'120px'}}>
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-6 mx-auto" style={{border : "1px solid #EBEBEB", padding:"28px 24px"}}>

                                    <div className="content mb-5">
                                        <h3 id="we-would-love-to-hear-from-you"><i className="fa-solid fa-user-shield"></i> Forgot Password</h3>
                                        <p style={{borderBottom:'1px #EBEBEB solid'}}></p>
                                    </div>

                                    <div className="form-group">
                                        <label htmlFor="name">Email</label>
                                        <input type="email" name="email" onKeyPress={this.onKeyUp} onChange={this.handleUsernameInput} id="email" className="form-control" />
                                    </div>
                                    <div className="form-group" style={{color:'#f00'}}>
                                        {authStatus}
                                    </div>
                                    <button type="button" onClick={this.resetPassword} className="btn btn-primary"> Submit</button>
                                </div>
                            </div>
                        </div>
                    </section>
                </>
            );

        }
    }
}

export default ForgotPassword;
