import React from "react";
import {toast} from "react-toastify";

import { CSVBoxButton } from "@csvbox/react";

const CsvBoxxComponent = () => {
    const user_id = localStorage.getItem('user_id');
    const token = localStorage.getItem('token');
    
    return (
        <CSVBoxButton className="btn btn-primary"
            licenseKey="B1xZZZ7hpfQF5xDhktGzx31qmH3a8D"
            lazy={true}
            user={{
                user_id: user_id
            }}
            onImport={(result, data) => {
                if (result) {
                    toast.success('Import Completed Successfully!');
                    setTimeout(() => {
                        window.location.href = "/home";
                    }, 2000);
                } else {
                    toast.error('here was an issue with your upload. We’re working a solution and will get back shortly.');
                }
            }}
            loadStarted={() => {
                console.log("loadStarted");
            }}
            onReady={() => {
                console.log("onReady");
            }}
            onClose={() => {
                console.log("onclosed");
            }}
            onSubmit={(data) => {
                console.log("onSubmit");
                console.log(data.import_id);
            }} 
             options={{
                  request_headers: {
                      "Authorization": "Bearer " + token
                  },
              }}
        >
            Import
        </CSVBoxButton>
    );
};

export default CsvBoxxComponent;