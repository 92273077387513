import React from 'react';

const PhoneNumberInput = ({ onPhoneNumberChange }) => {
    const handlePhoneNumberChange = (e) => {
        let input = e.target.value.replace(/\D/g, '');
        // Limit the length to 15 characters
        if (input.length > 15) {
            input = input.slice(0, 15);
        }
        onPhoneNumberChange(input);
    };

    return (
            <input
                type="tel"
                className="form-control"
                name="phoneNumber"
                onChange={handlePhoneNumberChange}
            />

    );
};

export default PhoneNumberInput;