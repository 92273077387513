import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import axios from "axios";
import {toast, ToastContainer} from "react-toastify";

const DynamicFormContact = ({ fields }) => {
    const initialState = Object.fromEntries(fields.map(field => [field.name, '']));
    const [formValues, setFormValues] = useState(initialState);
    const today = new Date();

    const handleChange = (name, value) => {
        setFormValues({ ...formValues, [name]: value });
    };

    const openPage = () => {
        window.location.href = "/manage-contact";
    }

    const handleDateChange = (name, date) => {
        handleChange(name, date);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        // You can perform further actions with the form data here
        console.log('Form submitted with values:', formValues);

        axios.post(`suppliers/add-contact`, formValues)
            .then(response => {
                toast.success('Contact Added Successfully!');
                setTimeout(() => {
                    openPage();
                }, 2000);

            }).catch(error => {
            console.error('There was an error!', error);

        });
    };

    return (
        <div className="container">
            <form onSubmit={handleSubmit}>
                {fields.map(field => (
                    <div key={field.name} className="mb-3">
                        <label htmlFor={field.name} className="form-label">{field.label}</label>
                        {field.type === 'date' ? (
                            <DatePicker
                                className="form-control"
                                id={field.name}
                                name={field.name}
                                selected={formValues[field.name]}
                                onChange={(date) => handleDateChange(field.name, date)}
                                dateFormat="MM-dd-yyyy"
                                minDate={today}
                                required={field.required || false}
                            />
                        ) : (
                            <div className="input-group">
                                {field.icon && (
                                    <span className="input-group-text">
                                      <i style={{fontSize:'18px'}} className={field.icon}></i>
                                    </span>
                                )}
                                <input
                                    type={field.type === 'tel' ? 'tel' : 'text'} // Use 'tel' type for phone numbers
                                    className="form-control"
                                    id={field.name}
                                    name={field.name}
                                    value={formValues[field.name]}
                                    onChange={(e) => handleChange(field.name, e.target.value)}
                                    required={field.required || false}
                                />
                            </div>
                        )}
                    </div>
                ))}
                <button type="submit" className="btn btn-primary">Submit</button>
            </form>
            <ToastContainer />
        </div>
    );
};

export default DynamicFormContact;
