import { Routes, Route, useParams } from 'react-router-dom';
import Home from './Pages/Home';
import Contact from './Pages/Contact';
import Login from './Pages/Login';
import Register from './Pages/Register';
import Signup from './Pages/Signup';
import TermsAndCondition from './Pages/TermsAndCondition';
import Upload from './Pages/Upload';
import ViewRecord from './Pages/ViewRecord';
import AddRecord from './Pages/AddRecord';
import EditRecord from './Pages/EditRecord';
import AddContact from './Pages/AddContact';
import EditContact from './Pages/EditContact';
import ManageAccount from './Pages/ManageAccount';
import ForgotPassword from "./Pages/ForgotPassword";

const App = () => {

  return (
      <>
        <Routes>
          <Route path="/home" element={<Home />} />
          <Route path="/manage-contact" element={<Contact />} />
          <Route path="/" element={<Login />} />
          <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/signup" element={<Signup />} />
            <Route path="/terms-and-conditions" element={<TermsAndCondition />} />
            <Route path="/upload" element={<Upload />} />
            <Route exact path="/view/:recordId" element={<ViewRecord/>}/>
            <Route path="/add-record" element={<AddRecord />} />
            <Route path="/edit-record/:Id" element={<EditRecord />} />
            <Route path="/add-contact" element={<AddContact />} />
            <Route path="/edit-contact/:contactId" element={<EditContact />} />
            <Route path="/manage-account" element={<ManageAccount />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
        </Routes>
      </>
  );
};

export default App;