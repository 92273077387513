import React from 'react';

const formatPhoneNumber = (phoneNumber) => {
    // Remove non-numeric characters
    let numericPhoneNumber = phoneNumber.replace(/\D/g, '');

    // Extract country code (assuming it is the first one or two digits)
    let countryCode = '';
    if (numericPhoneNumber.length >= 11) {
        countryCode = `+${numericPhoneNumber.slice(0, numericPhoneNumber.length - 10)}`;
        numericPhoneNumber = numericPhoneNumber.slice(-10);
    }

    // Format as (XXX) XXX-XXXX with optional country code
    const formattedPhoneNumber = `${countryCode}(${numericPhoneNumber.slice(0, 3)}) ${numericPhoneNumber.slice(3, 6)}-${numericPhoneNumber.slice(6, 10)}`;

    return formattedPhoneNumber;
};

const PhoneNumberComponent = ({ phoneNumber }) => {
    if(phoneNumber === null) {
        console.log('xxx', phoneNumber);
        return "";
    } else {
        const formattedNumber = formatPhoneNumber(phoneNumber);
        return <span>{formattedNumber}</span>;
    }

};

export default PhoneNumberComponent;
