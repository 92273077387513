import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import axios from 'axios';

//axios.defaults.baseURL = 'http://127.0.0.1:8000/';
axios.defaults.baseURL = 'https://api.nutribrandz.com/';
axios.defaults.headers.get["Content-Type"] = 'application/json';
axios.defaults.headers.post["Content-Type"] = 'application/x-www-form-urlencoded';
axios.defaults.headers.accept = 'application/json';

axios.interceptors.request.use(function (config) {
    const token = localStorage.getItem('token');
    if(token && config.url !== 'auth/token')
        config.headers.Authorization = `Bearer ${token}`;

    return config;
});

axios.interceptors.response.use(response => {
    return response;
}, error => {
    if (error.response.status === 401) {
        localStorage.removeItem('token');
        localStorage.removeItem('user_id');
        let currentUrl = window.location.href
        let url = new URL(currentUrl);
        let formUrl = url.pathname
        let appendUrlStr = "";
        if(formUrl !== '/login') {
            appendUrlStr = "?returnUrl="+formUrl;
        } else if(formUrl === '/login') {
            
        } else {
            window.location.href = "/login"+appendUrlStr;
        }

    }
    return error;
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <BrowserRouter>
        <App />
    </BrowserRouter>
);

