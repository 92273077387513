import Logo from '../logo.png';
import React, { Component }  from 'react';

export class Header extends Component {
    state = {

    }

    componentDidMount() {

    }

    logout(e) {
        e.preventDefault();
        localStorage.removeItem('token');
        localStorage.removeItem('user_id');
        window.location.href = "/login";
    }
    render() {
        return (
            <header className="navigation fixed-top">
                <div className="container">
                    <nav className="navbar navbar-expand-lg navbar-white">
                        <a className="navbar-brand order-1" href="/home">
                            <img className="img-fluid" width="100px" src={Logo} alt="Peak"/>
                        </a>
                        <div className="collapse navbar-collapse text-center order-lg-2 order-3" id="navigation">
                            <ul className="navbar-nav mx-auto">
                                

                                <li className="nav-item menu-border">
                                    <a className="nav-link" style={{fontSize:'16px'}} href="/home"><i className="fa-solid fa-house"></i> Home</a>
                                </li>

                                <li className="nav-item menu-border">
                                    <a className="nav-link" style={{fontSize:'16px'}} href="/upload"> <i className="fa-solid fa-upload"></i> Add Data</a>
                                </li>

                                <li className="nav-item menu-border">
                                    <a className="nav-link" style={{fontSize:'16px'}} href="/manage-contact"> <i className="fa-solid fa-address-book"></i> Contacts</a>
                                </li>

                                <li className="nav-item menu-border">
                                    <a className="nav-link" style={{fontSize:'16px'}} href="/manage-account"> <i className="fa-solid fa-user"></i> Account</a>
                                </li>
                               
                            </ul>
                        </div>

                        <div className="order-2 order-lg-3 d-flex align-items-center">
                            <button  onClick={(event) => {this.logout(event)}} className="border-0 order-1" type="button" data-toggle="collapse" data-target="#navigation" style={{background:'none', fontSize:'16px'}}>
                                <i className="fa-solid fa-right-from-bracket"></i> Logout
                            </button>
                        </div>

                    </nav>
                </div>
            </header>
        )
    }
}

    export default Header;