import React, { useState } from 'react';

const ChxBox = ({ onChange }) => {
    const [isChecked, setChecked] = useState(false);

    const handleCheckboxChange = () => {
        setChecked(!isChecked);
        onChange(!isChecked); // Notify parent component about the change
    };

    return (
            <input
                type="checkbox"
                style={{float: 'left'}}
                checked={isChecked}
                onChange={handleCheckboxChange}
            />
    );
};

export default ChxBox;