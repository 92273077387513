import React, { Component }  from 'react';
import axios from "axios";
import $ from "jquery";
import Header from "./Components/Header";
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'

export class Home extends Component{
    state = {
        records: [],
        search: "",
        innerRecords: [],
        innerContacts: []
    }

    onProcessAndDownload = () => {
        console.log('process and Download')
        axios.get(`suppliers/generate-data-file`)
            .then(response => {
                const data = response.data;
                let filename = data.filename;
                const element = document.createElement("a");
                element.href = axios.defaults.baseURL + 'suppliers/download-data-file/'+filename;
                element.download = "download.xlsx";
                document.body.appendChild(element); // Required for this to work in FireFox
                element.click();
            })

    }

    getSuppliers() {
        let _self = this;

        let dataObj = {
            search: _self.state.search,
            order: 'desc'
        }
        axios.put(`suppliers/get-suppliers`, dataObj)
            .then(response => {
                const records = response.data;
                _self.setState({ records });
            }).catch(error => {
            console.error('There was an error!', error);
        });
    }

    componentDidMount() {
        this.getSuppliers()
    }

    formatDate(dateString){
        if (dateString !== undefined && dateString.trim() !== '') {
            const [year, month, day] = dateString.split('-');
            const inputDate = new Date(year, month - 1, day, 0,0, 0);

            if (!isNaN(inputDate.getTime())) {
                const formattedDate = inputDate.toLocaleDateString('en-US', {
                    year: 'numeric',
                    month: '2-digit',
                    day: '2-digit',
                });
                return formattedDate;
            } else {
                return "Invalid Date";
            }
        } else {
            return "Invalid Input";
        }
    };

    formatDateTime(dateString){
        if(dateString !== undefined)
        {
            const myDate = new Date(dateString);

            return  myDate.toLocaleString('en-US', {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
                hour: '2-digit',
                minute: '2-digit',
                second: '2-digit',
            });

        }
    };

    openPage(e, SupplierDataId){
        e.preventDefault();
        window.location.href = "/view/"+SupplierDataId;
    }

    openInner(e, SupplierData, index) {
        e.preventDefault();
        console.log(SupplierData);
        console.log(index);
        axios.get(`suppliers/get-supplier-inner-data/${SupplierData.SupplierDataMasterId}`)
            .then(response => {
                const itemData = response.data.itemData;
                const contacts = response.data.contacts;
                
                console.log(contacts);
                
                let innerRecords = this.state.innerRecords;
                innerRecords[index] = itemData;
                this.setState({ innerRecords })
                
                let innerContacts = this.state.innerContacts
                innerContacts[index] = contacts;
                this.setState({ innerContacts })
            })
    }

    onSearchClick(e)
    {
        this.setState({ search: e.target.value })
        this.getSuppliers()
    }

    onSearch(e)
    {
        this.setState({ search: e.target.value })
    }

    onKeyUp (e)
    {
        if (e.charCode === 13) {
            console.log('13');
            this.onSearchClick(e);
        }
    };
    
    render() {
        return (
            <>
                <Header  />




                <section className="section pb-0" style={{ marginBottom: '130px'}}>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 mb-12 row"  style={{marginTop:'10px'}}>
                                <div className="input-group col-lg-7 mb-3">
                                    
                                </div>
                                <div className="input-group col-lg-5 mb-3">
                                    <input onKeyPress={(e) => this.onKeyUp(e)} type="text" className="form-control" onChange={(e) => this.onSearch(e)} placeholder="Search" aria-label="search" style={{padding:'20px'}} aria-describedby="basic-addon2" />
                                    <div className="input-group-append">
                                        <span onClick={(e) => this.onSearchClick(e)} className="input-group-text ti-search" id="basic-addon2"></span>
                                    </div>
                                </div>
                            </div>


                            <div className="col-lg-12 mb-12">



                                <article className="card">


                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-lg-12 row" style={{marginBottom:'10px'}}>
                                                <div className="col-lg-6" style={{fontSize:"20px", fontWeight:"bold"}}>
                                                        Current Quote
                                                </div>
                                                <div className="col-lg-6">
                                                    <button className='btn btn-success' onClick={this.onProcessAndDownload} style={{float:"right", marginRight:"-31px"}}>
                                                        <i className='ti-download'></i> Download
                                                    </button>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                        
                                        
                                        <table className="table table-bordered">
                                            <thead className="tableHead">
                                            <tr>
                                                <th scope="col">View</th>
                                                <th scope="col">Supplier Name</th>
                                                <th scope="col">Supplier Item #</th>
                                                <th scope="col">Item Description</th>
                                                <th scope="col">MOQ</th>
                                                <th scope="col">Pack Size</th>
                                                <th scope="col">U/M</th>
                                                <th scope="col"> Tier Qty 1 </th>
                                                <th scope="col">$ Cost </th>
                                                <th scope="col">Good Thru Date </th>
                                                <th scope="col">Created </th>
                                            </tr>
                                            </thead>
                                            <tbody>

                                            {this.state.records.map((p, index )=> (
                                                <React.Fragment key={index}>
                                                    <tr data-toggle={`collapse`} data-target={`#row${index}`} className="clickable" style={{cursor:'pointer'}}>
                                                        <td onClick={(e) => this.openPage(e, p.SupplierDataId)}><i className="ti-pencil-alt"></i></td>
                                                        <td onClick={(e) => this.openInner(e, p, index)}>{p.SupplierName}</td>
                                                        <td onClick={(e) => this.openInner(e, p, index)}>{p.SupplierItem}</td>
                                                        <td onClick={(e) => this.openInner(e, p, index)}>{p.ItemDescription}</td>
                                                        <td onClick={(e) => this.openInner(e, p, index)}>{p.MOQ}</td>
                                                        <td onClick={(e) => this.openInner(e, p, index)}>{p.PackSize}</td>
                                                        <td onClick={(e) => this.openInner(e, p, index)}>{p.UM}</td>
                                                        <td onClick={(e) => this.openInner(e, p, index)}>{p.TierQty1}</td>
                                                        <td onClick={(e) => this.openInner(e, p, index)}>{p.TierQty1Cost}</td>
                                                        <td onClick={(e) => this.openInner(e, p, index)}>{this.formatDate(p.GoodThruDate)}</td>
                                                        <td onClick={(e) => this.openInner(e, p, index)}>{this.formatDateTime(p.dateTimeCreated)}</td>
                                                    </tr>
                                                    <tr id={`row${index}`} className="collapse" >
                                                        <td colSpan="11">
                                                            
                                                            <table className="table table-bordered">
                                                                <thead className="subTableHead">
                                                                <tr>
                                                                    <th scope="col">View</th>
                                                                    <th scope="col">Supplier Name</th>
                                                                    <th scope="col">Supplier Item #</th>
                                                                    <th scope="col">Item Description</th>
                                                                    <th scope="col">MOQ</th>
                                                                    <th scope="col">Pack Size</th>
                                                                    <th scope="col">U/M</th>
                                                                    <th scope="col"> Tier Qty 1 </th>
                                                                    <th scope="col">$ Cost </th>
                                                                    <th scope="col">Good Thru Date </th>
                                                                    <th scope="col">Created </th>
                                                                </tr>
                                                                </thead>
                                                                <tbody>
                                                                {this.state.innerRecords[index] && this.state.innerRecords[index].map((xx, xxindex )=> (
                                                                    <tr>
                                                                        <td onClick={(e) => this.openPage(e, xx.SupplierDataId)}><i className="ti-pencil-alt"></i></td>
                                                                        <td>{xx.SupplierName}</td>
                                                                        <td>{xx.SupplierItem}</td>
                                                                        <td>{xx.ItemDescription}</td>
                                                                        <td>{xx.MOQ}</td>
                                                                        <td>{xx.PackSize}</td>
                                                                        <td>{xx.UM}</td>
                                                                        <td>{xx.TierQty1}</td>
                                                                        <td>{xx.TierQty1Cost}</td>
                                                                        <td>{this.formatDate(xx.GoodThruDate)}</td>
                                                                        <td>{this.formatDateTime(xx.dateTimeCreated)}</td>
                                                                    </tr>
                                                                ))}
                                                                </tbody>
                                                            </table>

                                                        </td>
                                                    </tr>
                                                </React.Fragment>) )}

                                            {
                                                this.state.records.length === 0 ? (<tr><td colSpan="10" style={{textAlign:'center'}}>No Records</td></tr>) : (<tr></tr>)
                                            }


                                            </tbody>
                                        </table>


                                    </div>
                                </article>
                            </div>



                            <div className="col-12">
                                <div className="border-bottom border-default"></div>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        );
    }
}

export default Home;
