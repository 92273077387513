import React, { Component }  from 'react';
import axios from "axios";
import $ from "jquery";
import Header from "./Components/Header";
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import PhoneNumberComponent from './Components/PhoneNumberComponent';

export class Contact extends Component{
    state = {
        records: []
    }

    getSuppliers() {
        let _self = this;
        axios.get(`suppliers/contacts`)
            .then(response => {
                const records = response.data.contacts;
                _self.setState({ records });
            }).catch(error => {
            console.error('There was an error!', error);
        });
    }

    componentDidMount() {
        this.getSuppliers()
    }

    formatDateTime(dateString){
        if(dateString !== undefined)
        {
            const myDate = new Date(dateString);

            return  myDate.toLocaleString('en-US', {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
                hour: '2-digit',
                minute: '2-digit',
                second: '2-digit',
            });

        }
    };

    openPage(e, ContactId){
        e.preventDefault();
        window.location.href = "/edit-contact/"+ContactId;
    }

    onAdd = (e) => {
        e.preventDefault();
        window.location.href = "/add-contact";
    };

    render() {
        return (
            <>
                <Header  />




                <section className="section pb-0" style={{ marginBottom: '130px'}}>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 mb-12 row"  style={{marginTop:'10px'}}>
                                <div className="col-lg-7 mb-3">
                                    <h3 style={{margin:"10px 0px 5px 25px"}}>
                                        Manage Contact
                                    </h3>
                                </div>
                                <div className="col-lg-5 mb-3" style={{textAlign:'right'}}>
                                    <button className='btn btn-primary' onClick={this.onAdd} >
                                        <i className="fa-solid fa-plus"></i> Add
                                    </button>
                                </div>
                            </div>


                            <div className="col-lg-12 mb-12">



                                <article className="card">


                                    <div className="card-body">
                                        

                                        <table className="table table-bordered">
                                            <thead className="tableHead">
                                            <tr>
                                                <th scope="col">Edit</th>
                                                <th scope="col">Name</th>
                                                <th scope="col">Email</th>
                                                <th scope="col">CellPhone</th>
                                                <th scope="col">OfficePhone</th>
                                                <th scope="col">Created </th>
                                            </tr>
                                            </thead>
                                            <tbody>

                                            {this.state.records.map((p, index )=> (
                                                <React.Fragment key={index}>
                                                    <tr data-toggle={`collapse`} data-target={`#row${index}`} className="clickable" style={{cursor:'pointer'}}>
                                                        <td onClick={(e) => this.openPage(e, p.ContactId)}><i className="ti-pencil-alt"></i></td>
                                                        <td>{p.Name}</td>
                                                        <td>{p.Email}</td>
                                                        <td><PhoneNumberComponent phoneNumber={p.CellPhone} /></td>
                                                        <td><PhoneNumberComponent phoneNumber={p.OfficePhone} /></td>
                                                        <td>{this.formatDateTime(p.DateTimeCreated)}</td>
                                                    </tr>
                                                </React.Fragment>) )}

                                            {
                                                this.state.records.length === 0 ? (<tr><td colSpan="10" style={{textAlign:'center'}}>No Records</td></tr>) : (<tr></tr>)
                                            }


                                            </tbody>
                                        </table>


                                    </div>
                                </article>
                            </div>



                            <div className="col-12">
                                <div className="border-bottom border-default"></div>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        );
    }
}

export default Contact;
